<template>
    <div class="row mb-5 align-items-center">
        <div class="col-12">
            <ValidationObserver ref="storeForm">
                <div class="row">
                    <div class="col-12">
                        <ValidationProvider name="name" rules="required"
                                            v-slot="{ valid, errors }">
                            <b-form-group :label="$t('course')">
                                <course-auto-complete v-model="form.course_id" :value-object="course">
                                </course-auto-complete>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12">
                        <ValidationProvider name="can_take_same_semester" rules="required"
                                            v-slot="{ valid, errors }">
                            <b-form-group :label="$t('can_take_same_semester')">
                                <true-false-selectbox v-model="form.can_take_same_semester" />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12">
                        <ValidationProvider name="department_codes" v-slot="{ valid, errors }">
                            <b-form-group :label="$t('department_codes')">
                                <department-selectbox v-model="department_cod" :multiple="true">
                                </department-selectbox>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12">
                        <ValidationProvider name="prerequisites" rules=""
                                            v-slot="{ valid, errors }">
                            <b-form-group :label="$t('prerequisites')">
                                <course-auto-complete v-model="prerequisitess" v-on:courseSelected="courseSelected">
                                </course-auto-complete>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12" v-if="courses && courses.length">
                        <table class="table table-bordered">
                            <tr v-for="c in courses" v-bind:key="c.id">
                                <td>{{c.text}}</td>
                                <td class="width-30">
                                    <b-button class="text-danger mr-1" variant="link" size="xs" @click="deleteCourse(c.value)">
                                        {{ $t('delete') }}
                                    </b-button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </ValidationObserver>
        </div>
        <div class="col-12 mt-3 d-flex">
            <b-button @click="createForm" type="button" variant="primary" class="btn-lg mr-2">
                {{ $t('save') }}
            </b-button>
        </div>
    </div>
</template>

<script>
//Component
import CourseAutoComplete from "@/components/interactive-fields/CourseAutoComplete";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
import TrueFalseSelectbox from "@/components/interactive-fields/TrueFalseSelectbox";
//Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
//Service
import CoursePrerequisitesService from "@/services/CoursePrerequisitesService";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        CourseAutoComplete,
        DepartmentSelectbox,
        TrueFalseSelectbox
    },
    data() {
        return {
            formLoading: false,
            prerequisitess: null,
            department_cod: [],
            data:null,
            id: 0,
            form: {
                department_codes:null,
                course_id:null
            },
            course: null,
            courses: [],
        }
    },
    methods: {
        async createForm() {
            this.form.department_codes= this.department_cod
            const isValid = await this.$refs.storeForm.validate()
            if (isValid) {
                this.data = {...this.form};
                if(this.data.can_take_same_semester===false){
                    this.data.can_take_same_semester = 0;
                }
                if(!this.courses.length){
                    return;
                }
                this.data.prerequisites = [];
                this.courses.forEach(item=>{
                    this.data.prerequisites.push(item.value);
                });
                this.takePrerequisities(this.data.prerequisites)
                    CoursePrerequisitesService.store(this.data, this.value)
                        .then((response) => {
                            this.id = response.data.data.id;
                            this.$emit('createFormSuccess', true);
                            this.$toast.success(this.$t("api." + response.data.message));
                        })
                        .catch((error) => {
                            this.showErrors(error,this.$refs.storeForm)
                        })

            }
        },
        takePrerequisities(x) {
            const myJ = JSON.stringify(x)
            this.data.prerequisites = myJ
        },
        courseSelected(course){
            if(this.courses.filter(item=>{
                return item.value == course.value;
            }).length){
                return;
            }
            this.courses.push(course);
        },
        deleteCourse(id){
            this.courses = this.courses.filter(item=>{
                return item.value!=id;
            });
        }
    }
}
</script>
